export {}

// Get the menu items and the toggle button
const menuToggle = document.querySelector("#toggleMobileNav");
const menuItems = document.querySelector("#navItems");

// perform the logic to toggle the menu.
if(menuToggle && menuItems) {
    menuToggle.addEventListener("click", () => {
        // If the data-open has not been set then it must be closed
        if(!menuToggle.hasAttribute('data-open')) {
            menuToggle.setAttribute("data-open", "");
            menuItems.setAttribute("data-open", "");
            menuToggle.innerHTML = `<i class="fa-light fa-xmark"></i>`
        } else {
            menuToggle.removeAttribute("data-open");
            menuItems.setAttribute("data-closing", "");
            menuToggle.innerHTML = `<i class="fa-light fa-bars"></i>`
        }
    });

    menuItems.addEventListener("animationend", () => {
        if(menuItems.hasAttribute('data-closing')) {
            console.log("I am animating")
            menuItems.removeAttribute("data-closing");
            menuItems.removeAttribute("data-open");
        }
    });


}