export {};

const planToggle = document.querySelector("#planToggle");

if(planToggle) {
    planToggle.addEventListener("change", (e:Event) => {
        if(e && e.target) {
            const target = e.target as HTMLInputElement;
            console.log(target.checked);
        }
    });
}