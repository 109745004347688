const scrollToTopButton = document.querySelector("#scrollToTop") as HTMLButtonElement;

if(scrollToTopButton) {
    document.addEventListener("scroll", (e) => {
        const pos = document.documentElement.scrollTop || document.body.scrollTop || 0

        if(pos > 500) {
            scrollToTopButton.style.display = "flex";
        } else {
            scrollToTopButton.style.display = "none";
        }
    });

    scrollToTopButton.addEventListener("click", (e:Event) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
}