const alertsCheckbox: HTMLInputElement|null = document.querySelector('#alertsToggle');
const alertsState: HTMLInputElement|null = document.querySelector('#alertsState');
if(alertsCheckbox && alertsState) {
    alertsCheckbox.addEventListener('change', (event) => {
        const isChecked = (<HTMLInputElement>event.target).checked;
        if(isChecked) {
            alertsState.value = '1';
        } else {
            alertsState.value = '0';
        }
    })
}